import React from "react";

class Checkbox extends React.Component {
  constructor({ onChange, checked }) {
    super(...arguments);

    this.state = {
      checked: checked || false
    };

    this.onClick = this.onClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.checked = newProps.checked;
  }

  get checked() {
    return this.state.checked;
  }
  set checked(val) {
    this.setState({ ...this.state, checked: val });
  }

  onClick(e) {
    this.checked = !this.state.checked;

    this.props.onChange && this.props.onChange(!this.state.checked);
  }

  render() {
    return (
      <span
        className={this.props.className}
        onClick={this.onClick}
        style={{ cursor: "pointer" }}
      >
        <span
          className={
            "far " + (this.state.checked ? "fa-check-square" : "fa-square")
          }
        />
        {this.props.children}
      </span>
    );
  }
}

export default Checkbox;
