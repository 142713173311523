import React from "react";
import Link from "../common/Link";
import Checkbox from "../common/Checkbox";
import Cookies from "js-cookie";

class DownloadAppModal extends React.Component {
  constructor({
    isVisible // Passed in props
  }) {
    super(...arguments);

    this.state = {
      isVisible: this.props.isVisible || false
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.setState({ isVisible: true });
  }

  closeModal() {
    if (typeof document !== "undefined") {
      let bodyElement = document.getElementsByTagName("BODY")[0];
      bodyElement.classList.remove("noScroll");
    }
    this.setState({ ...this.state, isVisible: false });
  }

  neverShowModal = val => {
    if (val === true) {
      Cookies.set("ignoreDownloadApp", val, { expires: 1, secure: true });
      setInterval(() => {
        this.closeModal();
      }, 500);
    } else {
      Cookies.remove("ignoreDownloadApp");
    }
  };

  render() {
    let { isVisible } = this.state;

    let isWebview = Cookies.get("webview");
    let downloadApp = Cookies.get("ignoreDownloadApp");
    if (isWebview || downloadApp) {
      return null;
    }

    if (isVisible) {
      let bodyElement = null;
      if (typeof document !== "undefined") {
        bodyElement = document.getElementsByTagName("BODY")[0];
      }
      bodyElement && bodyElement.classList.add("noScroll");
    } else {
      return null;
    }

    return (
      <div
        className="checkout-download-app-modal"
        style={{
          visibility: isVisible ? "visible" : "hidden",
          transition: "opacity 0.5s",
          opacity: isVisible ? "1" : "0"
        }}
      >
        <span
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            left: "0",
            visibility: isVisible ? "visible" : "hidden",
            backgroundColor: "#000000",
            transition: "opacity 0.5s",
            opacity: isVisible ? "0.65" : "0"
          }}
        />

        <div className="checkout-download-app-content">
          <div className="checkout-download-app-header">
            <h1>Download our new App</h1>
            <button className="cancel-button" onClick={this.closeModal}>
              X
            </button>
          </div>
          <hr />
          <div className="link-container">
            <Link href="https://itunes.apple.com/gb/app/kukd-takeaway-food-delivery/id1052050079?mt=8">
              <a target="_blank" rel="noopener">
                <img src="https://s3-eu-west-1.amazonaws.com/prod-consumer-cdn/img/v3/low/app-store.svg" />
              </a>
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.kukd&hl=en">
              <a target="_blank" rel="noopener">
                <img src="https://s3-eu-west-1.amazonaws.com/prod-consumer-cdn/img/v3/low/google-play.svg" />
              </a>
            </Link>
          </div>

          <Checkbox
            id="myCheck"
            type="checkbox"
            // ref={}
            onChange={val => this.neverShowModal(val)}
          >
            &nbsp;Don't show again today
          </Checkbox>
        </div>
      </div>
    );
  }
}

export default DownloadAppModal;
