import React from "react";
import { connect } from "react-redux";
import AppRoutes from "../../../appRoutes";
import Link from "./Link";
import { includes } from "lodash";

class Breadcrumbs extends React.Component {
  constructor({ routePath, whitelabelData }) {
    super(...arguments);
  }

  getBreadcrumbs(routePath) {
    let routes = [];
    let routePoints = routePath.split("/");

    // Remove url params
    routePoints = routePoints.filter(point => {
      return point[0] !== "?";
    });

    for (let i = 0; i < routePoints.length; i++) {
      if (routePoints[i] !== "") {
        routePoints[i] = unescape(routePoints[i]);

        let route = AppRoutes.getRoute(routePoints[i]);
        if (route.parent) {
          routes.push(AppRoutes.getRoute(route.parent));
        }
        if (!route.hideInBreadcrumbs) {
          routes.push(route);
        }
        if (route.hideChildren) {
          break;
        }
      }
    }
    routes.unshift(AppRoutes.getRoute("index"));
    
    // Remove temporary restaurant id number in breadcrumb display
    if (routes.length > 0) {
      if (!isNaN(routes[routes.length - 1].pretty)) {
        routes.splice(routes.length - 1, 1);
      }
    }

    return routes.map((route, i, arr) => {
      const modifiedRoute = route.pretty.replace("topic?name=", "")
      if (i + 1 === arr.length) {
        return modifiedRoute;
      } else {
        return (
          <React.Fragment key={i}>
            <span itemScope itemType="http://data-vocabulary.org/Breadcrumb">
              <Link pageName={route.pageName}>
                <a itemProp="url">
                  <span itemProp="title">{route.pretty.includes("topic?name=") ? modifiedRoute : route.pretty}</span>
                </a>
              </Link>
            </span>
            &nbsp;/&nbsp;
          </React.Fragment>
        );
      }
    });
  }

  render() {
    if (this.props.whitelabelData.isWhitelabel && this.isRestaurantPage()) {
      return null;
    }

    return (
      <div className="breadcrumbs">
        <div className="container-limit">
          <p className="crumbs">
            {this.getBreadcrumbs(this.props.routePath.currentRoutePath.includes("undefined") ? "Other Takeways" : this.props.routePath.currentRoutePath)}
          </p>
          {this.props.children}
        </div>
      </div>
    );
  }

  isRestaurantPage() {
    let isRestaurantPage = false;
    if (this.props.routePath.currentRoutePath[0] === "/") {
      let minusSlash = this.props.routePath.currentRoutePath.substr(1);
      if (minusSlash.split("/")[0] === "restaurant") {
        isRestaurantPage = true;
      }
    }
    return isRestaurantPage;
  }
}

const mapStateToProps = state => {
  return {
    routePath: state.routePath,
    whitelabelData: state.whitelabelData
  };
};

export default (Breadcrumbs = connect(
  mapStateToProps,
  null
)(Breadcrumbs));
