import React from "react";
import { connect } from "react-redux";
import Utils from "../../utils/Utils";
import Router from "next/router";
import {
  displayOnlyBasket,
  displayFullscreenBasket,
  setBasketList,
  setLoading,
} from "../../actions";
import ButtonKukd from "../common/ButtonKukd";
import API from "../../api";

class BasketMini extends React.Component {
   constructor({
    onClick, // Passed in props
    basketData,
    isClickable,
    restaurantData,
    killswitchData, // Redux Props
    displayOnlyBasket,
    displayFullscreenBasket, // Redux Dispatchers
    loaderGraphic
  }) {
    super(...arguments);
    this.onClick = this.onClick.bind(this);
    
    this.state = {
      checked: false,
      show: false,
      basketString: `0 baskets`,
      matchingBasket: {},
      baskets: [],
    };
  }

  onClick(isMobile) {
    if (isMobile) {
      this.props.displayOnlyBasket(
        !this.props.restaurantData.displayOnlyBasket
      );
    } else {
      if (this.props.isClickable) {
        window.scrollTo(0, 0);
        this.props.displayOnlyBasket(
          !this.props.restaurantData.displayOnlyBasket
        );
      }
    }
  }

  handleToggle = () =>
    this.setState((currentState) => ({ show: !currentState.show }));

  close = () => this.setState({ ...this.state, show: false });

  async componentDidMount() {
    if (this.props.accountData.loggedIn && Router.router.pathname === "/restaurants" || Router.router.pathname === "/restaurant") {
      const newBasketList = await API.getBaskets();
      this.props.setBasketList(newBasketList);
      this.setState({baskets: newBasketList});
    }

    this.getMatchingBasket();
  }

  async componentDidUpdate(prevProps) {
    
    let currPath = this.props.routePath.currentRoutePath.split("/")[1];
    let prevPath = prevProps.routePath.currentRoutePath.split("/")[1];

    if (prevProps.basketData !== this.props.basketData) {
      this.getMatchingBasket();
    };

    if (
      currPath !== prevPath ||
      !this.state.checked ||
      this.props.basketData.restaurantID !==
      prevProps.basketData.restaurantID ||
      this.props.basketData.itemsSubTotal !== prevProps.basketData.itemsSubTotal
    ) {
      if (this.props.accountData.loggedIn && Router.router.asPath.includes("restaurants" || "checkout")) {
        if (this.state.baskets.length !== this.props.basketData.basketList.length) {
          const newBasketList = await API.getBaskets();
          this.props.setBasketList(newBasketList);
        }
      }

      if (
        this.props.basketData.items.length !== prevProps.basketData.items.length
      ) {
        this.setState({
          checked: true,
          basketString: `${this.props.basketData.items.length ? this.props.basketData.items.length : this.props.basketData.setMeals.length} item(s)`,
        });
      }

      this.setState({ checked: true });

      //// /restaurants path ////
      if (currPath === "restaurants") {
        this.setState({
          matchingBasket: null,
          basketString: `${this.props.basketData.basketList.length || this.props.basketData.items.length} baskets`,
        });
      }

      if (
        currPath === "restaurants" &&
        this.props.accountData.loggedIn &&
        this.props.basketData.basketList.length === 0 &&
        this.props.basketData.items.length === 0
      ) {
        let zeroBaskets = 0
        this.setState({
          matchingBasket: null,
          basketString: `${zeroBaskets} baskets`,
        });
      }

      if (
        currPath === "restaurants" &&
        !this.props.accountData.loggedIn &&
        !this.props.basketData.items.length
      ) {
        this.setState({
          basketString: `${this.props.basketData.items.length} baskets`,
        });
      }

      if (
        currPath === "restaurants" &&
        !this.props.accountData.loggedIn &&
        this.props.basketData.items.length
      ) {
        this.getMatchingBasket();
        this.setState({ basketString: `${1} basket` });
      }

      //// /restaurant path ////
      if (
        currPath === "restaurant" &&
        this.props.accountData.loggedIn &&
        this.props.basketData.basketList.length
      ) {
        this.getMatchingBasket();
      };

      if (
          currPath === "restaurant" &&
          this.props.accountData.loggedIn &&
          this.props.basketData.setMeals.length > 0 &&
          this.props.basketData.items.length === 0
        ) {
          this.setState({basketString: `${1} item(s)`,})
        };

      if (
          currPath === "restaurant" &&
          this.props.accountData.loggedIn &&
          this.props.basketData.items.length === 0 && 
          !this.state.matchingBasket
        ) {
          let zeroBaskets = 0;
          this.setState({basketString: `${zeroBaskets} item(s)`,})
        };

      if (
        currPath === "restaurant" &&
        this.props.accountData.loggedIn &&
        this.props.basketData.items.length
      ) {
        this.setState({
          basketString: `${this.props.basketData.items.length} item(s)`,
        })
      };
    }
  };


  getMatchingBasket() {
   
    let newItemArr = [];

    let matchingBasket = {
      basket_uuid: this.props.basketData.uuid,
      basket_value: this.props.basketData.value,
      deliveryCollectionState: {
        deliverySelected: this.props.basketData.deliveryCollectionState
          .deliverySelected,
        collectionSelected: this.props.basketData.deliveryCollectionState
          .collectionSelected,
      },
      item_count: (this.props.basketData.items ? this.props.basketData.items.length : 0) + (this.props.basketData.setMeals.length || 0),
      last_updated: "2023-01-12T09:49:57.000Z",
      main_head_bg:
        "https://production-partner-cdn.s3.amazonaws.com/d2c6e68e-bf5a-4ade-89b4-6811fa8286ab/images/v1-1/main-head-bg.jpg",
      rest_id: this.props.basketData.restaurantID,
      rest_name: "",
    };

    if (this.props.accountData.loggedIn) {
      matchingBasket = this.props.basketData.basketList.find(
        (basket) => basket.rest_id === this.props.basketData.restaurantID
      );
    }
    // } else {
    //   matchingBasket.rest_name = this.props.restaurantData.restaurant.name;
    // }

    this.props.basketData.items.map((item) => {
      let matchingItemIdx = newItemArr.findIndex((i) => i.alias === item.alias);
      if (matchingItemIdx === -1) {
        newItemArr.push({ ...item, quantity: 1 });
      } else {
        newItemArr[matchingItemIdx].quantity =
          newItemArr[matchingItemIdx].quantity + 1;
      }
    });

    if (this.props.basketData.setMeals) {
      this.props.basketData.setMeals.map((meal) => {
        
        let matchingMealIdx = newItemArr.findIndex((i) => i.name === meal.name);
        if (matchingMealIdx === -1) {
          newItemArr.push({ ...meal, quantity: 1 });
        } else {
          newItemArr[matchingMealIdx].quantity =
            newItemArr[matchingMealIdx].quantity + 1;
        }
      });
    };

    this.setState({
      matchingBasket: { ...matchingBasket, items: newItemArr },
      basketString: `${(this.props.basketData.items ? this.props.basketData.items.length : 0) + (this.props.basketData.setMeals ? this.props.basketData.setMeals.length : 0)} item(s)`
,
    });

  };

  render() {
      return (
        <>

          <span
            className="basket non-mobile"
            onClick={async () => {
                this.handleToggle();
            }}
          >
            {this.renderElement(false)}
          </span>
          
          {/* basketData.items only and logged in */}
          {this.state.show &&
            this.props.basketData.basketList.length > 0 &&
            this.props.accountData.loggedIn && (
        
              <div className="multiBasketContainer">
                
                {this.state.matchingBasket === null ? (
                  this.props.basketData.basketList.map((basket, i) => {
                    const d = new Date(basket.last_updated);
                    const lastUpdatedDateFormat = d.toLocaleDateString();

                    return (
                      <div
                        className="multiBasketContent"
                        key={i}
                        onClick={async () => {
                          let restaurantID = basket.rest_id;
                          let pathname = "/restaurant";
                          Router.push(
                            {
                              pathname: pathname,
                              query: {
                                restaurantID: restaurantID,
                              },
                            },
                            `${pathname}/${restaurantID}`
                          );
                        }}
                      >
                        <div className="multiBasketDetails">
                          <div className="multiBasketImageContainer">
                            <img
                              src={basket.main_head_bg}
                              className="multiBasketImage multiBasketContentCirc"
                            />
                          </div>
                          <div className="multiBasketTextContainer">
                            <p className="basketsText restText">
                              {basket.rest_name}
                            </p>

                            {basket.item_count === 1 ? (
                              <p className="basketsText">
                                {basket.item_count} item in basket
                              </p>
                            ) : (
                              <p className="basketsText">
                                {basket.item_count} items in basket
                              </p>
                            )}

                            <p className="basketsText">
                              Last updated: {lastUpdatedDateFormat}
                            </p>
                            <p className="basketsText priceText">
                              Subtotal:{" "}
                              {Utils.currencyFormat(basket.basket_value)}
                            </p>
                          </div>
                        </div>
                        <span className="fas fa-chevron-right" />
                      </div>
                    );
                  })
                ) : (
                  <div className="multiBasketContent">
                    <div className="multiBasketDetails">
                      <div className="multiBasketImageContainer">
                        <img
                          src={this.state.matchingBasket.main_head_bg}
                          className="multiBasketImage multiBasketContentCirc"
                        />
                      </div>
                      <div className="multiBasketTextContainer">
                        {
                          <p className="basketsText restText">
                            {this.state.matchingBasket.rest_name}
                          </p>
                        }
                        
                    {this.state.matchingBasket.items.map((item, i) => {
                        return (
                          <>
                            <p className="basketsText" key={i}>
                              {`${item.quantity}x ${Utils.capitaliseWords(item.alias ? item.alias : item.name)}`}{" "}
                            
                            </p>

                          </>
                        );
                      })}
                      <p className="basketsText priceText">
                          Subtotal:{" "}
                          {Utils.currencyFormat(
                              (this.props.basketData.itemsSubTotal && this.props.basketData.setMealsSubTotal) ? 
                              (this.props.basketData.itemsSubTotal + this.props.basketData.setMealsSubTotal) : 
                              this.props.basketData.itemsSubTotal || this.props.basketData.setMealsSubTotal
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
          )}

          {/* basketData.setMeals only and logged in */}
          {/* {this.state.show &&
            this.props.basketData.basketList.length > 0 &&
            this.props.accountData.loggedIn && (
              console.log("true"),
              <div className="multiBasketContainer">
                {this.state.matchingBasket === null ? (
                  this.props.basketData.basketList.map((basket, i) => {
                    const d = new Date(basket.last_updated);
                    const lastUpdatedDateFormat = d.toLocaleDateString();

                    return (
                      <div
                        className="multiBasketContent"
                        key={i}
                        onClick={async () => {
                          let restaurantID = basket.rest_id;
                          let pathname = "/restaurant";
                          Router.push(
                            {
                              pathname: pathname,
                              query: {
                                restaurantID: restaurantID,
                              },
                            },
                            `${pathname}/${restaurantID}`
                          );
                        }}
                      >
                        <div className="multiBasketDetails">
                          <div className="multiBasketImageContainer">
                            <img
                              src={basket.main_head_bg}
                              className="multiBasketImage multiBasketContentCirc"
                            />
                          </div>
                          <div className="multiBasketTextContainer">
                            <p className="basketsText restText">
                              {basket.rest_name}
                            </p>

                            {basket.item_count === 1 ? (
                              <p className="basketsText">
                                {basket.item_count} item in basket
                              </p>
                            ) : (
                              <p className="basketsText">
                                {basket.item_count} items in basket
                              </p>
                            )}

                            <p className="basketsText">
                              Last updated: {lastUpdatedDateFormat}
                            </p>
                            <p className="basketsText priceText">
                              Subtotal:{" "}
                              {Utils.currencyFormat(basket.basket_value)}
                            </p>
                          </div>
                        </div>
                        <span className="fas fa-chevron-right" />
                      </div>
                    );
                  })
                ) : (
                  <div className="multiBasketContent">
                    <div className="multiBasketDetails">
                      <div className="multiBasketImageContainer">
                        <img
                          src={this.state.matchingBasket.main_head_bg}
                          className="multiBasketImage multiBasketContentCirc"
                        />
                      </div>
                      <div className="multiBasketTextContainer">
                        {
                          <p className="basketsText restText">
                            {this.state.matchingBasket.rest_name}
                          </p>
                        }

                        {this.state.matchingBasket.items.map((item, i) => {
                          return (
                            <>
                              <p className="basketsText" key={i}>
                                {`${item.quantity}x ${Utils.capitaliseWords(
                                  item.alias
                                )}`}{" "}
                                {item.alias_ext !== null || ""
                                  ? `${Utils.capitaliseWords(item.alias_ext)}`
                                  : null}
                              </p>
                            </>
                          );
                        })}
                        <p className="basketsText priceText">
                          Subtotal:{" "}
                          {Utils.currencyFormat(
                            this.props.basketData.itemsSubTotal
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
          )}; */}
          
          {this.state.show &&
            !this.props.accountData.loggedIn &&
            this.state.matchingBasket && (
              <div className="multiBasketContainer">
                {this.state.matchingBasket &&
                this.props.routePath.currentRoutePath.includes(
                  "/restaurants/"
                ) ? (
                  this.state.matchingBasket.items.map((basket, i) => {
                    if (i === 0) {
                      const d = new Date(
                        this.state.matchingBasket.last_updated
                      );
                      const lastUpdatedDateFormat = d.toLocaleDateString();
                      return (
                        <div
                          className="multiBasketContent"
                          key={i}
                          onClick={() => {
                            let restaurantID = this.state.matchingBasket
                              .rest_id;
                            let pathname = "/restaurant";
                            Router.push(
                              {
                                pathname: pathname,
                                query: {
                                  restaurantID: restaurantID,
                                },
                              },
                              `${pathname}/${restaurantID}`
                            )
                          }}
                        >
                          <div className="multiBasketDetails">
                            <div className="multiBasketImageContainer">
                              <img
                                src={this.state.matchingBasket.main_head_bg}
                                className="multiBasketImage multiBasketContentCirc"
                              />
                            </div>
                            <div className="multiBasketTextContainer">
                              <p className="basketsText restText">
                                {this.state.matchingBasket.rest_name}
                              </p>

                              {basket.item_count === 1 ? (
                                <p className="basketsText">
                                  {this.state.matchingBasket.item_count} item in
                                  basket
                                </p>
                              ) : (
                                <p className="basketsText">
                                  {this.state.matchingBasket.item_count} items
                                  in basket
                                </p>
                              )}

                              <p className="basketsText">
                                Last updated: {lastUpdatedDateFormat}
                              </p>
                              <p className="basketsText priceText">
                                Subtotal:{" "}
                                {Utils.currencyFormat(
                                  this.state.matchingBasket.basket_value
                                )}
                              </p>
                            </div>
                          </div>
                          <span className="fas fa-chevron-right" />
                        </div>
                      )
                    }
                  })
                ) : (
                  <div className="multiBasketContent">
                    <div className="multiBasketDetails">
                      <div className="multiBasketImageContainer">
                        <img
                          src={this.state.matchingBasket.main_head_bg}
                          className="multiBasketImage multiBasketContentCirc"
                        />
                      </div>
                      <div className="multiBasketTextContainer">
                        {
                          <p className="basketsText restText">
                            {this.state.matchingBasket.rest_name}
                          </p>
                        }
                        {this.state.matchingBasket.items.map((item, i) => {
                          return (
                            <>
                              <p className="basketsText" key={i}>
                                {`${item.quantity}x ${Utils.capitaliseWords(
                                  item.alias
                                )}`}{" "}
                                {item.alias_ext !== null || ""
                                  ? `${Utils.capitaliseWords(item.alias_ext)}`
                                  : null}
                              </p>
                            </>
                          )
                        })}
                        <p className="basketsText priceText">
                          Subtotal:{" "}
                          {Utils.currencyFormat(
                            this.props.basketData.itemsSubTotal
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
        </>
      )
  }

  renderElement(isMobile) {
    return (
      <ButtonKukd
        disabled={
          !this.props.isClickable &&
          this.props.killswitchData.globalOrderingDisabled
        }
        className={
          this.props.isClickable &&
          !this.props.killswitchData.globalOrderingDisabled
            ? "cursor-pointer"
            : "cursor-default"
        }
        onClick={(e) => this.onClick(isMobile)}
        loading={this.props.basketData.updatingBasket && isMobile}
      >
        <span className="icon fas fa-shopping-basket" />
        &nbsp;
        <span>{this.state.basketString}</span>
      </ButtonKukd>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    basketData: state.basketData,
    isClickable:
      state.basketData &&
      (state.basketData.items.length || state.basketData.setMeals.length),
    restaurantData: state.restaurantData,
    killswitchData: state.killswitchData,
    routePath: state.routePath,
    accountData: state.accountData,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    displayOnlyBasket: (val) => {
      dispatch(displayOnlyBasket(val));
    },
    displayFullscreenBasket: (val) => {
      dispatch(displayFullscreenBasket(val));
    },
    setBasketList: (baskets) => {
      dispatch(setBasketList(baskets));
    },
    setLoading: (loading) => {
      dispatch(setLoading(loading));
    },
  };
};

export default (BasketMini = connect(
  mapStateToProps,
  mapDispatchToProps
)(BasketMini));
