import React from "react";
import Link from "./Link";
import Cookies from "js-cookie";
import { LazyLoadImage } from "react-lazy-load-image-component";

class Footer extends React.Component {
  render() {
    const today = new Date();

    return (
      <footer className="footer">
        <div className="footer-main">
          <span className="footer-col">
            <span>
              <Link pageName="login">
                <a>Login</a>
              </Link>{" "}
              /{" "}
              <Link pageName="register">
                <a>Signup</a>
              </Link>
            </span>
            <span>
              <Link pageName="account">
                <a>My Account</a>
              </Link>
            </span>

            <span>
              <Link pageName="about">
                <a>About</a>
              </Link>
            </span>
            <span>
              <Link pageName="kukd-points">
                <a>Kukd Points</a>
              </Link>
            </span>
          </span>
          <span className="footer-col">
            {/* <span>
							<Link pageName="index">
								<a>Order a Takeaway</a>
							</Link>
						</span>

						<span>
							<Link pageName="index">
								<a>Book a Table</a>
							</Link>
						</span> */}

            <span>
              <Link href="/indian-takeaway">
                <a>Indian Takeaway</a>
              </Link>
            </span>

            <span>
              <Link href="/pizza-delivery">
                <a>Pizza Delivery</a>
              </Link>
            </span>

            <span>
              <Link pageName="takeaway">
                <a>All Cuisines</a>
              </Link>
            </span>
          </span>
          <span className="footer-col">
            <Link href="/takeaway/manchester">
              <a>Manchester</a>
            </Link>
            <Link href="/takeaway/cardiff">
              <a>Cardiff</a>
            </Link>
            <Link href="/takeaway/birmingham">
              <a>Birmingham</a>
            </Link>
            <Link href="/takeaway/bristol">
              <a>Bristol</a>
            </Link>
            <Link pageName="takeaway">
              <a>All Locations</a>
            </Link>
          </span>
          <span className="footer-images">
            <span>
              <Link href="https://itunes.apple.com/gb/app/kukd-takeaway-food-delivery-and-reservations/id1052050079">
                <a target="_blank" rel="noopener">
                  <LazyLoadImage
                    src="/static/img/apple_appstore.svg"
                    alt="apple app store"
                  />
                </a>
              </Link>
            </span>
            <span>
              <Link href="https://play.google.com/store/apps/details?id=com.kukd">
                <a target="_blank" rel="noopener">
                  <LazyLoadImage
                    src="/static/img/google_playstore.svg"
                    alt="google play store"
                  />
                </a>
              </Link>
            </span>
          </span>
          <span className="footer-col">
            <span>
              <Link pageName="privacy-policy">
                <a>Privacy Policy / T&Cs</a>
              </Link>
            </span>
            <span>
              <Link pageName="cookies">
                <a>How we use cookies</a>
              </Link>
            </span>
            <span>
              <Link pageName="contact">
                <a>Contact Us</a>
              </Link>
            </span>
            <span>
              <Link pageName="faq">
                <a>FAQs</a>
              </Link>
            </span>
          </span>
        </div>

        <div className="footer-social">
          <Link href="https://www.facebook.com/getkukd/">
            <a target="_blank" rel="noopener">
              <span className="social-logo fab fa-facebook-f" />
            </a>
          </Link>
          <Link href="https://twitter.com/GetKukd">
            <a target="_blank" rel="noopener">
              <span className="social-logo fab fa-twitter ml-2 mr-2" />
            </a>
          </Link>
          <Link href="https://www.instagram.com/get_kukd/">
            <a target="_blank" rel="noopener">
              <span className="social-logo fab fa-instagram" />
            </a>
          </Link>
        </div>

        <div className="footer-sub">
          <p>
            &copy; {this.companyName} {today.getFullYear()} All rights reserved
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
