import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { hideModal } from "../../actions";

class Modal extends React.Component {
  constructor({
    config,
    visible, // Redux Props
    hideModal // Redux dispatchers
  }) {
    super(...arguments);
  }

  render() {
    if (!this.props.visible) return null;

    return (
      <React.Fragment>
        <div
          className="modal-kukd"
          style={{
            visibility: !this.props.visible ? "hidden" : "visible",
            backgroundColor: this.props.config.backgroundColor,
            transition: "opacity " + this.props.config.animTime / 1000 + "s",
            opacity: this.props.visible ? "1" : "0"
          }}
        >
          <div className="modal-card">
            {this.props.config.overrideContent ? (
              this.props.config.overrideContent
            ) : (
              <>
                <h1 className="text-center">{this.props.config.title}</h1>
                <div className="text-center mb-4 mt-4">
                  {this.props.config.description}
                </div>

                {this.renderButtons()}
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderButtons() {
    let buttonElements = this.props.config.buttons.map((buttonConfig, i) => {
      return (
        <button
          key={i}
          type="button"
          className={buttonConfig.className + " w-100 " + (i !== 0 && "ml-3")}
          style={{ height: "40px" }}
          onClick={() => {
            this.props.hideModal();
            setTimeout(() => {
              if (buttonConfig.onClick) buttonConfig.onClick();
            }, this.props.config.animTime);
          }}
        >
          {buttonConfig.text}
        </button>
      );
    });

    return <div className="d-flex flex-row">{buttonElements}</div>;
  }
}
Modal.propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.any,
    overrideContent: PropTypes.any,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func
      })
    ),
    animTime: PropTypes.number,
    backgroundColor: PropTypes.string
  })
};
const mapStateToProps = state => {
  return {
    config: state.modalData.config,
    visible: state.modalData.visible
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    hideModal: () => {
      dispatch(hideModal());
    }
  };
};

export default (Modal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Modal));
