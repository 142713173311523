import React from "react";
class DropMenuKukd extends React.Component {
  constructor({
    options // Passed in props
  }) {
    super(...arguments);

    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.optionSelected = this.optionSelected.bind(this);
  }

  toggle() {
    this.setState({ ...this.state, isOpen: !this.state.isOpen });
  }
  close() {
    this.setState({ ...this.state, isOpen: false });
  }

  optionSelected(option) {
    option.onClick();
    this.setState({
      ...this.state,
      isOpen: false
    });
  }

  render() {
    return (
      <div className="select-kukd" onMouseLeave={this.close}>
        <a
          onClick={this.toggle}
          className="select-button d-flex align-items-center justify-content-center"
        >
          {this.props.initialSelector}
          {this.state.isOpen
            ? this.props.indicatorClosed
            : this.props.indicatorOpen}
        </a>
        {this.state.isOpen && (
          <div className="select-options">{this.renderOptions()}</div>
        )}
      </div>
    );
  }

  renderOptions() {
    if (!this.props.options) return null;

    return this.props.options.map((option, i) => {
      return (
        <button
          className="select-option"
          onClick={() => this.optionSelected(option)}
          key={i}
        >
          {option.text}
        </button>
      );
    });
  }
}
export default DropMenuKukd;
