import { connect } from "react-redux";
import Link from "./Link";
import ButtonKukd from "./ButtonKukd";
import Cookies from "js-cookie";
import { setCookiesAccepted } from "../../actions";
import TagManager from "react-gtm-module";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

class CookiesBanner extends React.Component {
  constructor({
    cookiesAccepted // Redux Props
  }) {
    super(...arguments);
  };

  render() {
    let banners = [];
    if (this.props.cookiesAccepted) return null;

    return (
      <div
        id="cookie-banner"
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          left: 0,
          zIndex: 1000,
          boxShadow: "0px 0px 10px 0px grey"
        }}
      >
        <div
          className="d-flex d-column justify-content-center"
          style={{
            background: "linear-gradient(to bottom right, #ffc600, #f49a19)",
            color: "white",
            fontSize: "11pt",
            padding: "10px 15px"
          }}
        >
          <div
            className="d-flex align-items-center"
            style={{ textAlign: "center" }}
          >
            <div>
              This website uses cookies to ensure you get the best experience on
              our website.&nbsp;
              <Link pageName="cookies">
                <a style={{ color: "white", textDecoration: "underline" }}>
                  Learn more about how we use cookies
                </a>
              </Link>
            </div>

            <ButtonKukd
              className="cookies-accept-button white-button ml-4"
              style={{ fontSize: "10pt", color: "#fa7921", fontWeight: 500 }}
              onClick={e => {
                Cookies.set("cookiesAccepted", true);
                this.props.setCookiesAccepted(true);
                if (publicRuntimeConfig.GTM_ID) {
                  const tagManagerArgs = {
                    gtmId: publicRuntimeConfig.GTM_ID,
                    dataLayer: {
                      event: "react-new-page"
                    }
                  };

                  TagManager.initialize(tagManagerArgs);
                };
              }}
            >
              Accept Cookies
            </ButtonKukd>
            
            <ButtonKukd
              className="cookies-accept-button white-button ml-4"
              style={{ fontSize: "10pt", color: "#fa7921", fontWeight: 500 }}
              onClick={e => {
                const cookies = document.cookie.split(';');
                for (let i = 0; i < cookies.length; i++) {
                  const cookie = cookies[i].trim();
                  const cookieName = cookie.split('=')[0];
                  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${publicRuntimeConfig.CONSUMER_DOMAIN};`;
                };
                Cookies.set("rejectNonEssential", true);
                Cookies.set("cookiesAccepted", true);
                this.props.setCookiesAccepted(true);
                
              }}
            >
              Reject non-essential cookies
            </ButtonKukd>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cookiesAccepted: state.accountData.cookiesAccepted
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCookiesAccepted: val => {
      dispatch(setCookiesAccepted(val));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookiesBanner);
